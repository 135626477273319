.mps-label-product-name{
    height: 40px;
    overflow: hidden;
    text-align: center;
}

.mps-label-product-price{
    height: 20px;
    overflow: hidden;
    text-align: center;
    font-weight:bold;
    color: red;
}

.mps-label-product-price-normal{
    height: 20px;
    overflow: hidden;
    text-align: center;
    font-weight:bold;
    color: hsl(0, 100%, 70%);
}   