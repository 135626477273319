#mps-theme-152F70{
    color: #152F70;
}
#mps-theme-152F70 .ant-time-picker-clock-icon{
    color: #152F70;
}
#mps-theme-152F70 .ant-calendar-picker-icon{
    color: #152F70;
}
#mps-theme-152F70 .mps-header .mps-header-menu{
    background-color: #152F70 !important;
}
#mps-theme-152F70 .mps-header{
    background-color: #152F70 !important;
}
#mps-theme-152F70 .mps-header:hover{
    background-color: hsl(223, 70%, 50%);
}
#mps-theme-152F70 .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(223, 70%, 50%);
}
#mps-theme-152F70 .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid #152F70;
}
#mps-theme-152F70 .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(223, 70%, 50%);
    color: hsl(223, 70%, 50%);
}
#mps-theme-152F70 .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(223, 70%, 50%);
    color: hsl(223, 70%, 50%);
}
#mps-theme-152F70 button{
    background-color: #152F70;
}
#mps-theme-152F70 button.ant-btn:hover{
    color: white ;
}
#mps-theme-152F70 .mps-display-options i{
    color: #152F70 !important;
}
.mps-theme-152F70.ant-btn-primary{
    background-color: #152F70;
}
#mps-theme-152F70 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: #152F70;
}
#mps-theme-152F70 .ant-tabs-tab-active.ant-tabs-tab{
    color: #152F70;
}
#mps-theme-152F70 a:hover {
    color: hsl(223, 70%, 50%);
}
#mps-theme-152F70 button a:hover{
    color: white;
}
#mps-theme-152F70 .ant-tabs-tab.div:hover {
    color: #152F70;
}
#mps-theme-152F70 .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(223, 90%, 90%);
}
#mps-theme-152F70 .ant-menu-item.ant-menu-item-selected span{
    color: #152F70;
}
#mps-theme-152F70 .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #152F70;
}
#mps-theme-152F70 .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: #152F70;
}
#mps-theme-152F70 .ant-tabs-tab:hover{
    color: #152F70;
}
#mps-theme-152F70 .ant-menu-submenu-title:hover{
    color: #152F70;
}
#mps-theme-152F70 .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: #152F70;
}
#mps-theme-152F70 .rbc-calendar button.rbc-active{
    background-color: hsl(223, 70%, 50%) !important;
}
#mps-theme-152F70 button:hover {
    background-color: hsl(223, 70%, 50%) !important;
}
.mps-theme-152F70.ant-btn-primary:hover {
    background-color: hsl(223, 70%, 50%) !important;
}
#mps-theme-152F70.ant-tooltip-inner{
    color: white !important;
    background-color: #152F70;
}