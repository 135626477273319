#mps-theme-volcano{
    color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-time-picker-clock-icon{
    color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-calendar-picker-icon{
    color: rgb(250, 84, 28);
}
#mps-theme-volcano .mps-header .mps-header-menu{
    background-color: rgb(250, 84, 28) !important;
}
#mps-theme-volcano .mps-header{
    background-color: rgb(250, 84, 28) !important;
}
#mps-theme-volcano{
    color: hsl(15, 60%, 40%);
}
#mps-theme-volcano .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(15, 60%, 40%);
}
#mps-theme-volcano .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid rgb(250, 84, 28);
}
#mps-theme-volcano .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(15, 60%, 40%);
    color: hsl(15, 60%, 40%);
}
#mps-theme-volcano .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(15, 60%, 40%);
    color: hsl(15, 60%, 40%);
}
#mps-theme-volcano button{
    background-color: rgb(250, 84, 28);
}
#mps-theme-volcano button.ant-btn:hover{
    color: white ;
}
#mps-theme-volcano .mps-display-options i{
    color:  rgb(250, 84, 28) !important;
}
.mps-theme-volcano.ant-btn-primary{
    background-color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-tabs-tab-active.ant-tabs-tab{
    color: rgb(250, 84, 28);
}
#mps-theme-volcano a:hover {
    color: hsl(15, 60%, 40%);
}
#mps-theme-volcano button a:hover{
    color: white;
}
#mps-theme-volcano .ant-tabs-tab.div:hover {
    color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(15, 90%, 90%) ;
}
#mps-theme-volcano .ant-menu-item.ant-menu-item-selected span{
    color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid rgb(250, 84, 28);
}
#mps-theme-volcano .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-tabs-tab:hover{
    color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-menu-submenu-title:hover{
    color: rgb(250, 84, 28);
}
#mps-theme-volcano .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: hsl(15, 96%, 55%);
}
#mps-theme-volcano .rbc-calendar button.rbc-active{
    background-color: hsl(15, 60%, 40%) !important;
}
#mps-theme-volcano button:hover{
    background-color: hsl(15, 60%, 40%) !important;
}
.mps-theme-volcano.ant-btn-primary:hover{
    background-color: hsl(15, 60%, 40%) !important;
}
#mps-theme-volcano.ant-tooltip-inner{
    color: white !important;
    background-color: rgb(250, 84, 28);
}