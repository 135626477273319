
.mps-restaurant-wrapper{
    position:absolute; top:54px; bottom:0px; left:0px; right:0px; overflow:hide;
}
.mps-restaurant-table-wrapper{
    height: calc(100vh - 260px);
    overflow: scroll;
}

.mps-restaurant-button{
    float: right;
    padding-right: 35px;
    height: 50px;
}
.mps-restaurant-button button{
    margin: 5px;
    height: 40px;
    font-size: 18px;
}
.mps-restaurant-invoice-content{
    height: calc(100vh - 50px);
}

.mps-restaurant-invoice-general{
    height: 270px;
    overflow: scroll;
    padding-left: 10px;
    padding-top: 10px;
}
.mps-restaurant-invoice-additional{
    height: 270px;
    overflow: scroll;
    padding-left: 10px;
    padding-top: 10px;
} 
.mps-restaurant-invoice-content .ant-tabs-bar{
    margin: 0px !important;
}

.mps-restaurant-invoice-content .ant-select-auto-complete.ant-select-lg .ant-input {
    height: 32px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.mps-display{
    display: block;
}
.mps-restaurant-detail{
    padding-left: 10px;
}
.mps-restaurant-table-content{
    height: calc(100vh - 480px);
    overflow: scroll;
}
.mps-restaurant-table-content .ant-table-tbody > tr > td {
    padding: 2px 2px !important;
}

.mps-restaurant-room-table-detail{
    padding-left: 10px;
}
.mps-card-room-table{
    width: 120px !important;
    margin:5px;
    padding:1px !important;
    /* border: 2px solid #666 !important; */
}

.mps-card-room-table-selected{
    background-color: teal;
}

.mps-restaurant-product-detail{
    padding-left: 10px;
}
.mps-card-product{
    border-radius: 5px !important;
    height: 145px;
    width: 120px;
    /* border: 2px solid #666 !important; */
    padding: 0px !important;
}
.mps-card-product-selected{
    border-radius: 5px !important;
    height: 145px;
    width: 120px;
    border: 2px solid forestgreen !important;
    padding: 0px !important;
}


