#mps-theme-838383{
    color: #838383;
}
#mps-theme-838383 .ant-time-picker-clock-icon{
    color: #838383;
}
#mps-theme-838383 .ant-calendar-picker-icon{
    color: #838383;
}
#mps-theme-838383 .mps-header .mps-header-menu{
    background-color: #838383 !important;
}
#mps-theme-838383 .mps-header{
    background-color: #838383 !important;
}
#mps-theme-838383 .mps-header:hover{
    background-color: hsl(0, 0%, 30%);
}
#mps-theme-838383 .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(0, 0%, 30%);
}
#mps-theme-838383 .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid #838383;
}
#mps-theme-838383 .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(0, 0%, 30%);
    color: hsl(0, 0%, 30%);
}
#mps-theme-838383 .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(0, 0%, 30%);
    color: hsl(0, 0%, 30%);
}
#mps-theme-838383 button{
    background-color: #838383;
}
#mps-theme-838383 button.ant-btn:hover{
    color: white ;
}
#mps-theme-838383 .mps-display-options i{
    color: #838383 !important;
}
.mps-theme-838383.ant-btn-primary{
    background-color: #838383;
}
#mps-theme-838383 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: #838383;
}
#mps-theme-838383 .ant-tabs-tab-active.ant-tabs-tab{
    color: #838383;
}
#mps-theme-838383 a:hover {
    color: hsl(0, 0%, 30%);
}
#mps-theme-838383 button a:hover{
    color: white;
}
#mps-theme-838383 .ant-tabs-tab.div:hover {
    color: #838383;
}
#mps-theme-838383 .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(0, 0%, 80%);
}
#mps-theme-838383 .ant-menu-item.ant-menu-item-selected span{
    color: #838383;
}
#mps-theme-838383 .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #838383;
}
#mps-theme-838383 .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: #838383;
}

#mps-theme-838383 .ant-tabs-tab:hover{
    color: #838383;
}
#mps-theme-838383 .ant-menu-submenu-title:hover{
    color: #838383;
}
#mps-theme-838383 .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: #838383;
}
#mps-theme-838383 .rbc-calendar button.rbc-active{
    background-color: hsl(0, 0%, 30%) !important;
}
#mps-theme-838383 button:hover {
    background-color: hsl(0, 0%, 30%) !important;
}
.mps-theme-838383.ant-btn-primary:hover {
    background-color: hsl(0, 0%, 30%) !important;
}
#mps-theme-838383.ant-tooltip-inner{
    color: black !important;
    background-color: #838383;
}