.mps-inventory-table-content{
    height: calc(100vh - 260px);
    overflow: scroll;
}
.mps-inventory-table-wrapper{
    height: calc(100vh - 260px);
    overflow: scroll;
}

.mps-inventory-button button{
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    /* font-size: 18px;
    height: 35px; */
}


.mps-inventory-button button i{
  /* font-size: 20px; */
}
.mps-inventory-wrapper{
    position:absolute; top:54px; bottom:0px; left:0px; right:0px; overflow:hide;
}
.mps-inventory-header{
    float: right !important;
    padding-right: 35px;
}
.mps-inventory-detail{
    padding-left: 10px !important;
}
.mps-inventory-detail-general{
    height: calc(100vh - 150px);
    overflow: scroll;
    padding-left: 10px !important;
    padding-top: 10px !important;
}
.mps-inventory-detail-additional{
    height: calc(100vh - 150px);
    overflow: scroll;
    padding-left: 10px !important;
    padding-top: 10px !important;
}

.mps-inventory-physical-table-content{
    height: calc(100vh - 323px);
    overflow: scroll;
}

.mps-inventory-button{
    position: fixed;
    bottom: 0;
    right: 15px;
}