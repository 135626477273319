/* .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 10px 10px !important;
} */


.footer-modal{
    border-top: 1px solid rgba(0, 0, 0, 0.1);;
    text-align: right;
    padding-top: 20px;
}
.footer-modal button{
   margin-left: 10px;
}

.label-required{
    color: red;
    margin-left: 5px;
    padding-top:5px;
}
.button-area button{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    height: 40px;
    font-size: 16px;
}
.c-hide{
    display: none;
}
.display-date{
    text-align: center;
}
.display-number{
    text-align: right;
}
.display-text{
    text-align: left;
}

.display-scroll{
    overflow: scroll;
}

.menu-transaction .ant-drawer-title{
    text-align: right;
}
.mps-header{
    height: 54px !important;
}
.mps-header a {
    color: white !important;
}
.ant-layout-header{
    line-height: 50px;
    padding: 0px 15px !important;
}
#subject{
    width: 250px;
    padding: 20px;
}
#subject button{
    margin: 10px;
}
button{
    border: none !important; 
}
.content-header {
    background-color: #e8e8e8 !important    ;
}

.mps-content{
    padding: 0 20px 0
}
.mps-sub-content{
    background: #fff;
    padding: 24px;
    margin: 0px;
    min-height: calc(100vh - 190px) !important;
}

.mps-date{
    text-align: center;
}
.mps-number{
    text-align: right;
}

.mps-permission-content{
    /* max-height: calc(100vh - 220px) !important; */
    max-height: 600px !important;
    overflow: scroll;
}

/* use css to set position of modal */
.vertical-center-modal {
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
  }
  
  .vertical-center-modal:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
  }
  
  .vertical-center-modal .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    text-align: left;
  }

  .mps-edit-form-content{
    max-height: calc(100vh - 220px) !important;
    /* max-height: 500px !important; */
    overflow: scroll;
}

.mps-search-header-collapse {
    margin-bottom: 10px;
}

.mps-search-header-panel-title {
    font-weight: bold;
    font-size: 14px;
}

.mps-site-collapse-panel {
    background: #f7f7f7;
    border-radius: 2px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
  }

.mps-search-header-panel-title .mps-site-collapse-panel {
    background: #f7f7f7;
    border-radius: 2px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
}

.mps-display-options i :hover {
    cursor: pointer;
}

.mps-display-sort-field i :hover {
    cursor: pointer;
}

.mps-display-search-field {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
    padding-bottom: 10px
}

.mps-display-sort-field {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
}

.mps-display-column-result {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
    padding-bottom: 10px
}

.mps-card-items {
    box-shadow: none !important;
    padding: 0px !important;
    overflow: hidden;
    height: 50px;
}
.ant-input {
    height: 40px !important;
    font-size: 16px !important;
}
.ant-form-item {
    font-size: 16px !important;
    margin-bottom: 10px !important;

}
.ant-select-selection--single {
    min-height: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
}
.ant-select-selection--multiple {
    min-height: 40px !important;
    line-height: 40px !important;
}
.ant-select-selection__rendered {
    line-height: 40px !important;
}

.ant-input-number {
    height: 40px !important;
    font-size: 16px !important;
    line-height: 40px !important;
    text-align: right;
}
.ant-select {
    font-size: 16px !important;
}
.ant-select-auto-complete {
    font-size: 16px !important;
    line-height: 40px;
}
.ant-btn {
    height: 40px !important;
    font-size: 16px !important;
}
.mps-delete-item {
    font-size: 14px;
    font-weight: bold;
    color: red !important;
}

.mps-delete-item:hover {
    cursor: pointer;
}

.dock-full div {
    width: 100%;
}

.mps-display {
    display: block;
}

.mps-none {
    display: none !important;
}

.ant-form-explain {
    color: seagreen;
    width: 20px;
    float: right;
    position: relative;
    margin-top: 0px !important;
    margin-right: -25px;
    font-weight: bold;
    font-size: 20px !important;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    border-bottom-color: seagreen !important;
}

.mps-form-tooltip {
    position: absolute;
    font-weight: bold;
    font-size: 20px;
    margin: 10px;
    color: green;
}

.display-date {
    text-align: center;
}

.display-numner {
    text-align: right;
}

.mps-ant-card-grid {
    padding: 5px 0px 10px 2px;
    box-shadow: none !important;
}

.mps-filter-icon {
    float: left;
    font-size: 17px;
    margin-top: 10px;
    margin-left: 10px;
}

.mps-print-content {
    font-family: Arial, Helvetica, sans-serif;
    margin: 75.84px 75.84px 75.84px 75.84px;
}

.print-content {
    font-family: Arial, Helvetica, sans-serif;
    margin: 75.84px 75.84px 75.84px 75.84px;
    top: '0'
}

.mps-font-size {
    font-size: 10pt;
}

.mps-font-size-report {
    font-size: 12pt;
}

.mps-texteditor-disabled {
    background: #f5f5f5 !important;
}

.mps-card-padding-none .ant-card-body {
    padding: 0px !important;
}

.mps-card-padding .ant-card-body {
    padding: 1px 0px 0px 10px !important;
}

.mps-inventory-detail-general .ant-card-body {
    padding: 0px !important;
}

.mps-tab-productlist .ant-tabs-nav .ant-tabs-tab {
    margin-top: 1px;
    margin: 0px !important;
    padding: 0px 7px 5px 5px !important;
    font-size: 17px;
}

.mps-tab-productlist .ant-tabs-nav-wrap {
    margin-top: 1px;
    margin-left: 40px !important;
}

.mps-layout-report {
    font-family: 'Arial';
    margin: 75.84px 75.84px 75.84px 75.84px;
    margin-top: auto;
}


/* .mps-resize-icon .resize-handle-vertical {
    height: 20px !important;
    width: 20px !important;
    margin: auto!important;
    border: solid black;
    border-width: 0 3px 3px 0;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    background-image: url("https://image.flaticon.com/icons/svg/714/714416.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
} */

.mps-resize-icon .resize-handle-vertical {
    height: 5px !important;
    cursor: row-resize !important;
}

.mps-resize-icon .resize-handle-vertical:hover {
    background-color: #bfbfbf !important;
}

.resize-handle-horizon:hover {
    background-color: #bfbfbf !important;
}

.mps-resize-icon .resize-handle-horizon {
    cursor: col-resize !important;
}
.ant-table {
    font-size: 16px;
}
.ant-table-thead>tr>th {
    text-align: center !important;
    padding: 7px 7px !important;
}

.ant-table-tbody>tr>td {
    padding: 8px 8px !important;
}

.cell-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    -o-column-break-inside: avoid;
    -ms-column-break-inside: avoid;
}

.ant-table-pagination.ant-pagination {
    float: left !important;
    margin: 5px 0 !important;
}

.mps-card-product-selected {
    opacity: 0.5;
    border-color: 3px;
    border: 5px solid;
    border-color: forestgreen;
}

.mps-card-product{
    width: 120px !important;
    height: 120px !important;
    margin: 5px !important;
}

.mps-card-product-restaurant{
    height: 145px !important;
}

.ant-card {
    font-size: 13px !important;
}

.mps-card-product .ant-card-body {
  padding: 0px !important;
}

.ant-input-number {
    width: 100% !important;
}

.ant-input-number-input {
    text-align: right !important;
    padding: 0px 25px 0px 0px !important;
}

.mps-ant-tabs-header {
    padding: 0px 0px 0px 10px !important;
}

.mps-ant-tabs-detail {
    padding-right: 10px !important;
}

.ant-calendar-picker-input {
    text-align: center;
}

.ant-calendar-picker-input.ant-input {
    text-align: center !important;
}

.mps-link-forgot-password {
    background-color: white !important;
    font-size: 18px !important;
}

.mps-login-contain {
    height: calc(100vh - 0px);
    padding-top: 100px;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    line-height: 55px;
    height: 55px !important;
    font-size: 16px !important;
}
/* 
.ant-calendar-picker-icon{
    margin-top: -15px !important;
} */

.ant-menu .mps-menu-icon{
    padding-left: 30px;
}

.ant-menu .mps-menu-icon i{
    font-size: 18px !important;
    position: absolute;
    margin-left: -25px;
    margin-top: 20px;
}
.ant-menu .mps-sub-menu-icon{
    padding-left: 40px;
}
.ant-menu .mps-sub-menu-icon  i{
    font-size: 18px !important;
    position: absolute;
    margin-left: -25px;
    margin-top: 10px;
}
.ant-menu .ant-menu-item{
    padding-left: 20px !important;
}
.ant-breadcrumb{
    font-weight: bold;
}
.ant-breadcrumb-link span:first-child {
    padding-left: 25px;
}
.ant-breadcrumb-link i {
    font-size: 18px !important;
    position: absolute;
    margin-left: -25px;
}

/* .mps-tab-productlist div:first-child{
    margin-top: 1px;
    height: 40px;
} */

.mps-ant-pagination-fixed{
    height: fit-content !important;
    margin-top: 5px !important;
    z-index: 2 !important;
    position: fixed !important;
    margin-left: 115px !important;
}

.mps-ant-button-saved-fixed{
    margin-top: 5px !important;
    z-index: 2 !important;
    position: fixed !important;
    margin-left: 625px !important;
}

.hidden-delete i.anticon.anticon-delete, .hidden-delete i.anticon.anticon-close {
    display: none;
}

.hidden-delete .ant-upload.ant-upload-select{
    display: unset;
}
.mps-total-amount1{
    font-size: 25px !important;
    color: green !important;
    font-weight: bold;
    height: 35px !important;
}

.mps-total-amount2{
    font-size: 25px !important;
    color: red !important;
    font-weight: bold;
    height: 35px !important;
}
.mps-day-off{
    background-color: red !important;
}

button :hover{
    cursor: pointer;
}

td.mps-linked-value a{
    color: seagreen !important;
    font-weight: 600;
}       

td.mps-linked-value a:hover{
    color: hsl(146, 40%, 50%) !important;
    text-decoration: underline hsl(146, 40%, 50%) solid !important;
}       

.mps-project-button{
    bottom: 10px;
    right: 10px;
    float: right;
}
.ant-menu-submenu-title{
    padding-left: 15px;
    font-size: 16px !important;
}

.ant-modal{
    width: 80% !important;
    top: 40px !important;
}

.ant-tabs-nav-container{
    font-size: 16px !important;
}
.ant-modal-body {
    padding-top: 10px !important;
    padding-right: 10px !important;
}
.ant-form label {
    font-size: 16px !important;
}

.mps-display-options{
    /* display: none; */
}