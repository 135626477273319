#mps-theme-9F111B{
    color: #9F111B;
}
#mps-theme-9F111B .ant-time-picker-clock-icon{
    color: #9F111B;
}
#mps-theme-9F111B .ant-calendar-picker-icon{
    color: #9F111B;
}
#mps-theme-9F111B .mps-header .mps-header-menu{
    background-color: #9F111B !important;
}
#mps-theme-9F111B .mps-header{
    background-color: #9F111B !important;
}
#mps-theme-9F111B .mps-header:hover{
    background-color: hsl(356, 70%, 50%);
}
#mps-theme-9F111B .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(356, 70%, 50%);
}
#mps-theme-9F111B .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid #9F111B ;
}
#mps-theme-9F111B .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(356, 70%, 50%);
    color: hsl(356, 70%, 50%);
}
#mps-theme-9F111B .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(356, 70%, 50%);
    color: hsl(356, 70%, 50%);
}
#mps-theme-9F111B button{
    background-color: #9F111B;
}
#mps-theme-9F111B button.ant-btn:hover{
    color: white ;
}
#mps-theme-9F111B .mps-display-options i{
    color: #9F111B !important;
}
.mps-theme-9F111B.ant-btn-primary{
    background-color: #9F111B;
}
#mps-theme-9F111B .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: #9F111B;
}
#mps-theme-9F111B .ant-tabs-tab-active.ant-tabs-tab{
    color: #9F111B;
}
#mps-theme-9F111B a:hover {
    color: hsl(356, 70%, 50%);
}
#mps-theme-9F111B button a:hover{
    color: white;
}
#mps-theme-9F111B .ant-tabs-tab.div:hover {
    color: #9F111B;
}
#mps-theme-9F111B .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(356, 90%, 90%);
}
#mps-theme-9F111B .ant-menu-item.ant-menu-item-selected span{
    color: #9F111B;
}
#mps-theme-9F111B .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #9F111B;
}
#mps-theme-9F111B .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: #9F111B;
}
#mps-theme-9F111B .ant-tabs-tab:hover{
    color: #9F111B;
}
#mps-theme-9F111B .ant-menu-submenu-title:hover{
    color: #9F111B;
}
#mps-theme-9F111B .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: #9F111B;
}
#mps-theme-9F111B .rbc-calendar button.rbc-active{
    background-color: hsl(356, 70%, 50%) !important;
}
#mps-theme-9F111B button:hover {
    background-color: hsl(356, 70%, 50%) !important;
}
.mps-theme-9F111B.ant-btn-primary:hover {
    background-color: hsl(356, 70%, 50%) !important;
}
#mps-theme-9F111B.ant-tooltip-inner{
    color: white !important;
    background-color: #9F111B;
}