#mps-theme-magenta{
    color: magenta;
}
#mps-theme-magenta .ant-time-picker-clock-icon{
    color: magenta;
}
#mps-theme-magenta .ant-calendar-picker-icon{
    color: magenta;
}
#mps-theme-magenta .mps-header .mps-header-menu{
    background-color: magenta !important;
}
#mps-theme-magenta .mps-header{
    background-color: magenta !important;
}
#mps-theme-magenta .mps-header:hover{
    background-color: hsl(300, 60%, 40%);
}
#mps-theme-magenta .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(300, 60%, 40%);
}
#mps-theme-magenta .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid magenta;
}
#mps-theme-magenta .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(300, 60%, 40%);
    color: hsl(300, 60%, 40%);
}
#mps-theme-magenta .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(300, 60%, 40%);
    color: hsl(300, 60%, 40%);
}
#mps-theme-magenta button{
    background-color: magenta ;
}
#mps-theme-magenta button.ant-btn:hover{
    color: white ;
}
#mps-theme-magenta .mps-display-options i{
    color:  magenta !important;
}
.mps-theme-magenta.ant-btn-primary{
    background-color: magenta ;
}
#mps-theme-magenta .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: magenta;
}
#mps-theme-magenta .ant-tabs-tab-active.ant-tabs-tab{
    color: magenta;
}
#mps-theme-magenta button a:hover{
    color: white;
}
#mps-theme-magenta a:hover {
    color: hsl(300, 60%, 40%);
}
#mps-theme-magenta .ant-tabs-tab.div:hover {
    color: magenta;
}
#mps-theme-magenta .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(300, 90%, 90%);
}
#mps-theme-magenta .ant-menu-item.ant-menu-item-selected span{
    color: magenta;
}
#mps-theme-magenta .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid magenta;
}
#mps-theme-magenta .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: magenta;
}
#mps-theme-magenta .ant-tabs-tab:hover{
    color: magenta;
}
#mps-theme-magenta .ant-menu-submenu-title:hover{
    color: magenta;
}
#mps-theme-magenta .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: magenta;
}
#mps-theme-magenta .rbc-calendar button.rbc-active{
    background-color: hsl(300, 60%, 40%) !important;
}
#mps-theme-magenta button:hover {
    background-color: hsl(300, 60%, 40%) !important;
}
.mps-theme-magenta.ant-btn-primary:hover {
    background-color: hsl(300, 60%, 40%) !important;
}
#mps-theme-magenta.ant-tooltip-inner{
    color: white !important;
    background-color: magenta;
}