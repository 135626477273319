#mps-theme-pink{
    color: pink;
}
#mps-theme-pink .ant-time-picker-clock-icon{
    color: pink;
}
#mps-theme-pink .ant-calendar-picker-icon{
    color: pink;
}
#mps-theme-pink .mps-header .mps-header-menu{
    color: black;
    background-color: pink !important;
}
#mps-theme-pink .mps-header{
    color: black;
    background-color: pink !important;
}
#mps-theme-pink .mps-header:hover{
    color: black;
    background-color: hsl(350, 60%, 60%);
}
#mps-theme-pink .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(350, 60%, 60%);
}
#mps-theme-pink .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid pink;
}
#mps-theme-pink .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(350, 60%, 60%);
    color: hsl(350, 60%, 60%);
}
#mps-theme-pink .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(350, 60%, 60%);
    color: hsl(350, 60%, 60%);
}
#mps-theme-pink button{
    color: black;
    background-color: pink;
}
#mps-theme-pink button.ant-btn:hover{
    color: white ;
}
#mps-theme-pink .mps-display-options i{
    color:  pink !important;
}
.mps-theme-pink.ant-btn-primary{
    color: black;
    background-color: pink;
}
#mps-theme-pink .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: pink;
}
#mps-theme-pink .ant-tabs-tab-active.ant-tabs-tab{
    color: pink;
}
#mps-theme-pink a:hover {
    color: hsl(350, 60%, 60%);
}
#mps-theme-pink button a:hover{
    color: black;
}
#mps-theme-pink .ant-tabs-tab.div:hover {
    color: pink;
}
#mps-theme-pink .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(350, 60%, 60%);
}
#mps-theme-pink .ant-menu-item.ant-menu-item-selected span{
    color: pink;
}
#mps-theme-pink .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid pink;
}
#mps-theme-pink .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: pink;
}
#mps-theme-pink .ant-tabs-tab:hover{
    color: pink;
}
#mps-theme-pink .ant-menu-submenu-title:hover{
    color: pink;
}
#mps-theme-pink .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: pink;
}
#mps-theme-pink .rbc-calendar button.rbc-active{
    background-color: hsl(350, 60%, 60%) !important;
}
#mps-theme-pink button:hover {
    background-color: hsl(350, 60%, 60%) !important;
}
.mps-theme-pink.ant-btn-primary:hover {
    background-color: hsl(350, 60%, 60%) !important;
}
#mps-theme-pink.ant-tooltip-inner{
    color: hsl(350, 60%, 60%) !important;
    background-color: pink;
}