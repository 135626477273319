#mps-theme-4D1D4D{
    color: #4D1D4D;
}
#mps-theme-4D1D4D .ant-time-picker-clock-icon{
    color: #4D1D4D;
}
#mps-theme-4D1D4D .ant-calendar-picker-icon{
    color: #4D1D4D;
}
#mps-theme-4D1D4D .mps-header .mps-header-menu{
    background-color: #4D1D4D !important;
}
#mps-theme-4D1D4D .mps-header{
    background-color: #4D1D4D !important;
}
#mps-theme-4D1D4D .mps-header:hover{
    background-color: hsl(300, 70%, 50%);
}
#mps-theme-4D1D4D .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(300, 70%, 50%);
}
#mps-theme-4D1D4D .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid #4D1D4D;
}
#mps-theme-4D1D4D .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(300, 70%, 50%);
    color: hsl(300, 70%, 50%);
}
#mps-theme-4D1D4D .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(300, 70%, 50%);
    color: hsl(300, 70%, 50%);
}
#mps-theme-4D1D4D button{
    background-color: #4D1D4D ;
}
#mps-theme-4D1D4D button.ant-btn:hover{
    color: white ;
}
#mps-theme-4D1D4D .mps-display-options i{
    color: #4D1D4D !important;
}
.mps-theme-4D1D4D.ant-btn-primary{
    background-color: #4D1D4D ;
}
#mps-theme-4D1D4D .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: #4D1D4D;
}
#mps-theme-4D1D4D .ant-tabs-tab-active.ant-tabs-tab{
    color: #4D1D4D;
}
#mps-theme-4D1D4D a:hover {
    color: hsl(300, 70%, 50%);
}
#mps-theme-4D1D4D button a:hover{
    color: white;
}
#mps-theme-4D1D4D .ant-tabs-tab.div:hover {
    color: #4D1D4D;
}
#mps-theme-4D1D4D .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(300, 90%, 90%);
}
#mps-theme-4D1D4D .ant-menu-item.ant-menu-item-selected span{
    color: #4D1D4D;
}
#mps-theme-4D1D4D .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #4D1D4D;
}
#mps-theme-4D1D4D .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: #4D1D4D;
}
#mps-theme-4D1D4D .ant-tabs-tab:hover{
    color: #4D1D4D;
}
#mps-theme-4D1D4D .ant-menu-submenu-title:hover{
    color: #4D1D4D;
}
#mps-theme-4D1D4D .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: #4D1D4D;
}
#mps-theme-4D1D4D .rbc-calendar button.rbc-active{
    background-color: hsl(300, 70%, 50%) !important;
}
#mps-theme-4D1D4D button:hover {
    background-color: hsl(300, 70%, 50%) !important;
}
.mps-theme-4D1D4D.ant-btn-primary:hover {
    background-color: hsl(300, 70%, 50%) !important;
}
#mps-theme-4D1D4D.ant-tooltip-inner{
    color: white !important;
    background-color: hsl(300, 70%, 50%);
}