#mps-theme-seagreen{
    color: seagreen;
}
#mps-theme-seagreen .ant-time-picker-clock-icon{
    color: seagreen;
}
#mps-theme-seagreen .ant-calendar-picker-icon{
    color: seagreen;
}
#mps-theme-seagreen .mps-header .mps-header-menu{
    background-color: seagreen !important;
}
#mps-theme-seagreen .mps-header{
    background-color: seagreen !important;
}
#mps-theme-seagreen .mps-header:hover{
    background-color: hsl(120, 40%, 30%);
}
#mps-theme-seagreen .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(120, 40%, 30%);
}
#mps-theme-seagreen .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid seagreen;
}
#mps-theme-seagreen .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(120, 40%, 30%);
    color: hsl(120, 40%, 30%);
}
#mps-theme-seagreen .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(120, 40%, 30%);
    color: hsl(120, 40%, 30%);
}
#mps-theme-seagreen button{
    background-color: seagreen;
}
#mps-theme-seagreen button.ant-btn:hover{
    color: white ;
}
#mps-theme-seagreen .mps-display-options i{
    color: seagreen !important;
}
.mps-theme-seagreen.ant-btn-primary{
    color: white;
    background-color: seagreen;
}
#mps-theme-seagreen .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: seagreen;
}
#mps-theme-seagreen .ant-tabs-tab-active.ant-tabs-tab{
    color: seagreen;
}
#mps-theme-seagreen a:hover {
    color: hsl(120, 40%, 30%);
}
#mps-theme-seagreen button a:hover{
    color: white;
}
#mps-theme-seagreen .ant-tabs-tab.div:hover {
    color: seagreen;
}
#mps-theme-seagreen .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(120, 90%, 90%) ;
}
#mps-theme-seagreen .ant-menu-item.ant-menu-item-selected span{
    color: seagreen;
}
#mps-theme-seagreen .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid seagreen;
}
#mps-theme-seagreen .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: seagreen;
}
#mps-theme-seagreen .ant-tabs-tab:hover{
    color: seagreen;
}
#mps-theme-seagreen .ant-menu-submenu-title:hover{
    color: seagreen;
}
#mps-theme-seagreen .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: seagreen;
}
#mps-theme-seagreen .rbc-calendar button.rbc-active{
    background-color: hsl(120, 40%, 30%) !important;
}
#mps-theme-seagreen button:hover {
    background-color: hsl(120, 40%, 30%) !important;
}
.mps-theme-seagreen.ant-btn-primary:hover {
    background-color: hsl(120, 40%, 30%) !important;
}
#mps-theme-seagreen.ant-tooltip-inner{
    color: white !important;
    background-color: seagreen;
}