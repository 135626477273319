#mps-theme-red{
    color: #e91e63;
}
#mps-theme-red .ant-time-picker-clock-icon{
    color: #e91e63;
}
#mps-theme-red .ant-calendar-picker-icon{
    color: #e91e63;
}
#mps-theme-red .mps-header .mps-header-menu{
    background-color: #e91e63 !important;
}
#mps-theme-red .mps-header{
    background-color: #e91e63 !important;
}
#mps-theme-red .mps-header:hover{
    background-color: hsl(0, 60%, 40%);
}
#mps-theme-red .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(0, 60%, 40%);
}
#mps-theme-red .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid #e91e63;
}
#mps-theme-red .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(0, 60%, 40%);
    color: hsl(0, 60%, 40%);
}
#mps-theme-red .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(0, 60%, 40%);
    color: hsl(0, 60%, 40%);
}
#mps-theme-red button{
    background-color: #e91e63;
}
#mps-theme-red button.ant-btn:hover{
    color: white ;
}
#mps-theme-red .mps-display-options i{
    color: #e91e63 !important;
}
.mps-theme-red.ant-btn-primary{
    background-color: #e91e63;
}
#mps-theme-red .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: #e91e63;
}
#mps-theme-red .ant-tabs-tab-active.ant-tabs-tab{
    color: #e91e63;
}
#mps-theme-red a:hover {
    color: rgb(250, 84, 28);
}
#mps-theme-red button a:hover{
    color: white;
}
#mps-theme-seagreen button a:hover{
    color: white;
}
#mps-theme-red .ant-tabs-tab.div:hover {
    color: #e91e63;
}
#mps-theme-red .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(0, 90%, 90%) !important;
}
#mps-theme-red .ant-menu-item.ant-menu-item-selected span{
    color: #e91e63;
}
#mps-theme-red .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #e91e63;
}
#mps-theme-red .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: #e91e63;
}
#mps-theme-red .ant-tabs-tab:hover{
    color: #e91e63;
}
#mps-theme-red .ant-menu-submenu-title:hover{
    color: #e91e63;
}
#mps-theme-red .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: #e91e63;
}
.mps-theme-red .ant-menu.ant-menu-sub :hover{
    color: #e91e63;
}


.mps-theme-red .ant-menu_item.ant-menu-item-active :hover{
    color: #e91e63 ;
}

.ant-menu_item.mps-theme-red.ant-menu-item-active a{
    color: #e91e63;
    background-color: #e91e63;
}

.ant-menu-item.mps-theme-red.ant-menu-item-selected a{
    color: #e91e63 !important;
    background-color: hsl(0, 90%, 90%)  !important;
}
#mps-theme-red .rbc-calendar button.rbc-active{
    background-color: hsl(0, 60%, 40%) !important;
}
#mps-theme-red button:hover {
    background-color: hsl(0, 60%, 40%) !important;
}
.mps-theme-red.ant-btn-primary:hover {
    background-color: hsl(0, 60%, 40%) !important;
}
#mps-theme-red.ant-tooltip-inner{
    color: white !important;
    background-color: rgb(250, 84, 28);
}
#mps-theme-red.ant-tooltip-inner{
    color: white !important;
    background-color: #e91e63;
}