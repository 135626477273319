#mps-theme-greekblue{
    color:rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-time-picker-clock-icon{
    color:rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-calendar-picker-icon{
    color:rgb(47, 84, 235);
}
#mps-theme-greekblue .mps-header .mps-header-menu{
    background-color: rgb(47, 84, 235) !important;
}
#mps-theme-greekblue .mps-header{
    background-color: rgb(47, 84, 235) !important;
}
#mps-theme-greekblue .mps-header:hover{
    background-color: hsl(228, 40%, 40%);
}
#mps-theme-greekblue .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(228, 40%, 40%);
}
#mps-theme-greekblue .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(228, 40%, 40%);
    color: hsl(228, 40%, 40%);
}
#mps-theme-greekblue .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(228, 40%, 40%);
    color: hsl(228, 40%, 40%);
}
#mps-theme-greekblue button{
    background-color: rgb(47, 84, 235);
}
#mps-theme-greekblue button.ant-btn:hover{
    color: white ;
}
#mps-theme-greekblue .mps-display-options i{
    color:  rgb(47, 84, 235) !important;
}
.mps-theme-greekblue.ant-btn-primary{
    background-color: rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-tabs-tab-active.ant-tabs-tab{
    color: rgb(47, 84, 235);
}
#mps-theme-greekblue a:hover {
    color: (228, 40%, 40%);
}
#mps-theme-greekblue button a:hover{
    color: white;
}
#mps-theme-greekblue .ant-tabs-tab.div:hover {
    color: rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(228, 90%, 90%);
}
#mps-theme-greekblue .ant-menu-item.ant-menu-item-selected span{
    color: rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-tabs-tab:hover{
    color: rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-menu-submenu-title:hover{
    color: rgb(47, 84, 235);
}
#mps-theme-greekblue .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: rgb(47, 84, 235);
}
#mps-theme-greekblue .rbc-calendar button.rbc-active{
    background-color: hsl(228, 40%, 40%) !important;
}
#mps-theme-greekblue button:hover {
    background-color: hsl(228, 40%, 40%) !important;
}
.mps-theme-greekblue.ant-btn-primary:hover {
    background-color: hsl(228, 40%, 40%) !important;
}
#mps-theme-greekblue.ant-tooltip-inner{
    color: white !important;
    background-color: rgb(47, 84, 235);
}