#mps-theme-395781{
    color: #395781;
}
#mps-theme-395781 .ant-time-picker-clock-icon{
    color: #395781;
}
#mps-theme-395781 .ant-calendar-picker-icon{
    color: #395781;
}
#mps-theme-395781 .mps-header .mps-header-menu{
    background-color: #395781 !important;
}
#mps-theme-395781 .mps-header{
    background-color: #395781 !important;
}
#mps-theme-395781 .mps-header:hover{
    background-color: hsl(215, 70%, 50%);
}
#mps-theme-395781 .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(215, 70%, 50%);
}
#mps-theme-395781 .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid #395781;
}
#mps-theme-395781 .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(215, 70%, 50%);
    color: hsl(215, 70%, 50%);
}
#mps-theme-395781 .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(215, 70%, 50%);
    color: hsl(215, 70%, 50%);
}
#mps-theme-395781 button{
    background-color: #395781;
}
#mps-theme-395781 button.ant-btn:hover{
    color: white ;
}
#mps-theme-395781 .mps-display-options i{
    color: #395781 !important;
}
.mps-theme-395781.ant-btn-primary{
    background-color: #395781;
}
#mps-theme-395781 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: #395781;
}
#mps-theme-395781 .ant-tabs-tab-active.ant-tabs-tab{
    color: #395781;
}
#mps-theme-395781 a:hover {
    color: hsl(215, 70%, 50%);
}
#mps-theme-395781 button a:hover{
    color: white;
}
#mps-theme-395781 .ant-tabs-tab.div:hover {
    color: #395781;
}
#mps-theme-395781 .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(215, 90%, 90%);
}
#mps-theme-395781 .ant-menu-item.ant-menu-item-selected span{
    color: #395781;
}
#mps-theme-395781 .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #395781;
}
#mps-theme-395781 .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: #395781;
}
#mps-theme-395781 .ant-tabs-tab:hover{
    color: #395781;
}
#mps-theme-395781 .ant-menu-submenu-title:hover{
    color: #395781;
}
#mps-theme-395781 .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: #395781;
}
#mps-theme-395781 .rbc-calendar button.rbc-active{
    background-color: hsl(215, 70%, 50%) !important;
}
#mps-theme-395781 button:hover {
    background-color: hsl(215, 70%, 50%) !important;
}
.mps-theme-395781.ant-btn-primary:hover {
    background-color: hsl(215, 70%, 50%) !important;
}
#mps-theme-395781.ant-tooltip-inner{
    color: white !important;
    background-color: #395781;
}