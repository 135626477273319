#mps-theme-A73B00{
    color: #A73B00;
}
#mps-theme-A73B00 .ant-time-picker-clock-icon{
    color: #A73B00;
}
#mps-theme-A73B00 .ant-calendar-picker-icon{
    color: #A73B00;
}
#mps-theme-A73B00 .mps-header .mps-header-menu{
    background-color: #A73B00 !important;
}
#mps-theme-A73B00 .mps-header{
    background-color: #A73B00 !important;
}
#mps-theme-A73B00 .mps-header:hover{
    background-color: hsl(21, 70%, 50%);
}
#mps-theme-A73B00 .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(21, 70%, 50%);
}
#mps-theme-A73B00 .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid #A73B00;
}
#mps-theme-A73B00 .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(21, 70%, 50%);
    color: hsl(21, 70%, 50%);
}
#mps-theme-A73B00 .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(21, 70%, 50%);
    color: hsl(21, 70%, 50%);
}
#mps-theme-A73B00 button{
    background-color: #A73B00;
}
#mps-theme-A73B00 button.ant-btn:hover{
    color: white ;
}
#mps-theme-A73B00 .mps-display-options i{
    color: #A73B00 !important;
}
.mps-theme-A73B00.ant-btn-primary{
    background-color: #A73B00;
}
#mps-theme-A73B00 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: #A73B00;
}
#mps-theme-A73B00 .ant-tabs-tab-active.ant-tabs-tab{
    color: #A73B00;
}
#mps-theme-A73B00 a:hover {
    color: hsl(21, 70%, 50%);
}
#mps-theme-A73B00 button a:hover{
    color: white;
}
#mps-theme-A73B00 .ant-tabs-tab.div:hover {
    color: #A73B00;
}
#mps-theme-A73B00 .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(21, 90%, 90%);
}
#mps-theme-A73B00 .ant-menu-item.ant-menu-item-selected span{
    color: #A73B00;
}
#mps-theme-A73B00 .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #A73B00;
}
#mps-theme-A73B00 .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: #A73B00;
}
#mps-theme-A73B00 .ant-tabs-tab:hover{
    color: #A73B00;
}
#mps-theme-A73B00 .ant-menu-submenu-title:hover{
    color: #A73B00;
}
#mps-theme-A73B00 .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: #A73B00;
}
#mps-theme-A73B00 .rbc-calendar button.rbc-active{
    background-color: hsl(21, 70%, 50%) !important;
}
#mps-theme-A73B00 button:hover {
    background-color: hsl(21, 70%, 50%) !important;
}
.mps-theme-A73B00.ant-btn-primary:hover {
    background-color: hsl(21, 70%, 50%) !important;
}
#mps-theme-A73B00.ant-tooltip-inner{
    color: white !important;
    background-color: #A73B00;
}