#mps-theme-green{
    color: green;
}
#mps-theme-green .ant-time-picker-clock-icon{
    color: green;
}
#mps-theme-green .ant-calendar-picker-icon{
    color: green;
}
#mps-theme-green .mps-header .mps-header-menu{
    background-color: green !important;
}
#mps-theme-green .mps-header{
    background-color: green !important;
}
#mps-theme-green .mps-header:hover{
    background-color: hsl(120, 40%, 30%);
}
#mps-theme-green .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(120, 40%, 30%);
}
#mps-theme-green .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid green;
}
#mps-theme-green .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(120, 40%, 30%);
    color: hsl(120, 40%, 30%);
}
#mps-theme-green .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(120, 40%, 30%);
    color: hsl(120, 40%, 30%);
}
#mps-theme-green button{
    background-color: green !important;
}
#mps-theme-green button.ant-btn:hover{
    color: white ;
}
#mps-theme-green .mps-display-options i{
    color:  green !important;
}
.mps-theme-green.ant-btn-primary{
    background-color: green;
}
#mps-theme-green .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: green;
}
#mps-theme-green .ant-tabs-tab-active.ant-tabs-tab{
    color: green;
}
#mps-theme-green a:hover {
    color: hsl(120, 40%, 30%);
}
#mps-theme-green button a:hover{
    color: white;
}
#mps-theme-green .ant-tabs-tab.div:hover {
    color: green;
}
#mps-theme-green .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(120, 90%, 90%);
}
#mps-theme-green .ant-menu-item.ant-menu-item-selected span{
    color: green;
}
#mps-theme-green .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid green;
}
#mps-theme-green .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: green;
}
#mps-theme-green .ant-tabs-tab:hover{
    color: green;
}
#mps-theme-green .ant-menu-submenu-title:hover{
    color: green;
}
#mps-theme-green .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: green;
}
#mps-theme-green .rbc-calendar button.rbc-active{
    background-color: hsl(120, 40%, 30%) !important;
}
#mps-theme-green button:hover {
    background-color: hsl(120, 40%, 30%) !important;
}
.mps-theme-green.ant-btn-primary:hover {
    background-color: hsl(120, 40%, 30%) !important;
}
#mps-theme-green.ant-tooltip-inner{
    color: white !important;
    background-color: green;
}