#mps-theme-cyan{
    color: cyan;
}
#mps-theme-cyan .ant-time-picker-clock-icon{
    color: cyan;
}
#mps-theme-cyan .ant-calendar-picker-icon{
    color: cyan;
}
#mps-theme-cyan .mps-header .mps-header-menu{
    color: black;
    background-color: cyan !important;
}
#mps-theme-cyan .mps-header{
    color: black;
    background-color: cyan !important;
}
#mps-theme-cyan .mps-header:hover{
    color: black;
    background-color: hsl(180, 50%, 60%);
}
#mps-theme-cyan .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid hsl(180, 50%, 60%);
}
#mps-theme-cyan .ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 2px solid cyan;
}
#mps-theme-cyan .ant-menu-horizontal > .ant-menu-submenu:hover{
    border-bottom: 2px solid hsl(180, 50%, 60%);
    color: hsl(180, 50%, 60%);
}
#mps-theme-cyan .ant-menu-horizontal > .ant-menu-submenu-open:hover{
    border-bottom: 2px solid hsl(180, 50%, 60%);
    color: hsl(180, 50%, 60%);
}
#mps-theme-cyan button{
    color: black;
    background-color: cyan;
}
#mps-theme-cyan button.ant-btn:hover{
    color: white ;
}
#mps-theme-cyan .mps-display-options i{
    color:  cyan !important;
}
.mps-theme-cyan.ant-btn-primary{
    color: black;
    background-color: cyan;
}
#mps-theme-cyan .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: cyan;
}
#mps-theme-cyan .ant-tabs-tab-active.ant-tabs-tab{
    color: cyan;
}
#mps-theme-cyan a:hover {
    color: hsl(180, 50%, 60%);
}
#mps-theme-cyan button a:hover{
    color: black;
}
#mps-theme-cyan .ant-tabs-tab.div:hover {
    color: cyan;
}
#mps-theme-cyan .ant-menu-item.ant-menu-item-selected{
    background-color: hsl(180, 90%, 90%);
}
#mps-theme-cyan .ant-menu-item.ant-menu-item-selected span{
    color: cyan;
}
#mps-theme-cyan .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid cyan;
}
#mps-theme-cyan .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected{
    color: cyan;
}
#mps-theme-cyan .ant-tabs-tab:hover{
    color: cyan;
}
#mps-theme-cyan .ant-menu-submenu-title:hover{
    color: cyan;
}
#mps-theme-cyan .ant-menu.ant-menu-sub.ant-menu-vertical :hover{
    color: cyan;
}
#mps-theme-cyan .rbc-calendar button.rbc-active{
    background-color: hsl(180, 50%, 60%) !important;
}
#mps-theme-cyan button:hover {
    background-color: hsl(180, 50%, 60%) !important;
}
.mps-theme-cyan.ant-btn-primary:hover {
    background-color: hsl(180, 50%, 60%) !important;
}
#mps-theme-cyan.ant-tooltip-inner{
    color: black !important;
    background-color: cyan;
}